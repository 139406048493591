import { IResolvers } from '@graphql-tools/utils';
import merge from 'lodash/merge';

import { resolveCompanyCompatibleContent } from './resolve-company-compatibleContent';
import { createConnectionResolvers } from './resolve-connection';
import { resolveMessagePreview } from './resolve-message-preview';
import { resolveNode, resolveNodes } from './resolve-node';
import { resolveViewer } from './resolve-viewer';
import { Context } from './resolvers.type';

export const resolvers: () => IResolvers<unknown, Context> = () => {
  return merge(createConnectionResolvers(), {
    Query: {
      node: resolveNode,
      nodes: resolveNodes,
      viewer: resolveViewer,
      evaluateMessagePreviewRequest: resolveMessagePreview,
    },
    Company: {
      compatibleContent: resolveCompanyCompatibleContent,
    },
  });
};
