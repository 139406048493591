import { GQLConnectionTypeNames, GQLTypeNames, GraphQLNode, MockConnection } from '../types';

interface ConnectionOptions {
  page?: number;
  limit?: number;
  includeTotalCount?: boolean;
}
interface ConnectionOptionsWithoutTotalCount extends ConnectionOptions {
  includeTotalCount?: false;
}
interface ConnectionOptionsWithTotalCount extends ConnectionOptions {
  includeTotalCount: true;
}

export function createConnection<T extends GQLTypeNames, N extends GraphQLNode>(
  typeName: T,
  nodes: N[],
  options?: ConnectionOptionsWithoutTotalCount
): MockConnection<T, N>;

export function createConnection<T extends GQLTypeNames, N extends GraphQLNode>(
  typeName: T,
  nodes: N[],
  options?: ConnectionOptionsWithTotalCount
): MockConnection<T, N> & { totalCount: number };

export function createConnection<T extends GQLTypeNames, N extends GraphQLNode>(
  typeName: T,
  nodes: N[],
  options: ConnectionOptions = {}
): MockConnection<T, N> {
  const { page = 0, limit = 5, includeTotalCount } = options;

  const edges = nodes.map((node) => {
    return {
      node: {
        ...node,
        __typename: typeName,
      },
      cursor: node.id,
    };
  });

  const pageOfEdges = edges.slice(page * limit, (page + 1) * limit);

  const connection: MockConnection<T, N> = {
    __typename: `${typeName}Connection` as GQLConnectionTypeNames,
    edges: pageOfEdges,
    pageInfo: {
      startCursor: edges.length > 0 ? (pageOfEdges[0] || {}).cursor : '',
      endCursor: edges.length > 0 ? (pageOfEdges[pageOfEdges.length - 1] || {}).cursor : '',
      hasNextPage: edges.length > (page + 1) * limit,
      hasPreviousPage: page > 0,
    },
    totalCount: includeTotalCount ? nodes.length : undefined,
  };

  return connection;
}
