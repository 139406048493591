import { faker } from '@faker-js/faker';

import { nbaImages, blogImages } from './images';

export const mockMap = {
  String: {
    email: () => faker.internet.email(),
    phone: () => faker.phone.number('555-###-####'),
    subscribedPhone: () => faker.phone.number('+1555#######'),
    firstName: () => faker.name.firstName(),
    lastName: () => faker.name.lastName(),
    productName: () => faker.commerce.productName(),
    productUrl: () => faker.internet.url(),
    addressLine1: () => faker.address.streetAddress(),
    addressLine2: () => faker.address.secondaryAddress(),
    city: () => faker.address.city(),
    zip: () => faker.address.zipCode(),
    country: () => faker.address.country(),
    attentiveDomain: () => faker.internet.domainName(),
    timezone: () => faker.address.timeZone(),
    domain: () => faker.internet.domainName(),
    imageUrl: () => faker.image.imageUrl(),
    credentialName: () => faker.lorem.word(),
    termsLink: () => faker.internet.url(),
    privacyLink: () => faker.internet.url(),
  },

  Url: {
    value: () => 'https://via.placeholder.com/300',
  },

  NextBestAction: {
    title: () => faker.lorem.sentence(),
    imageUrl: () => faker.helpers.arrayElement(nbaImages),
    ctaUrl: () => faker.internet.url(),
    description: () => faker.lorem.paragraph(2),
  },

  Account: {
    firstName: () => faker.name.firstName(),
    lastName: () => faker.name.lastName(),
    email: () => faker.internet.email(),
    phone: () => faker.phone.number('555-###-####'),
    authenticators: () => [faker.helpers.arrayElement(['AUTHENTICATOR_SMS'])],
  },

  BlogPost: {
    imageUrl: () => faker.helpers.arrayElement(blogImages),
    categoryName: () =>
      faker.helpers.arrayElement(['Trends and Insights', 'SMS Marketing', 'News']),
    subtitle: () => faker.company.catchPhrase(),
    title: () =>
      faker.helpers.arrayElement([
        '900,000 Conversations Show This Is What Consumers Want to Talk About With Brands',
        'Announcing the Next Evolution of Concierge, Powered by Attentive AI™',
        'What Excites Marketers Most About the Possibilities of AI? We Asked 5 Brands at Shoptalk.',
      ]),
    slug: () =>
      faker.helpers.arrayElement([
        'conversational-messaging-insights',
        'concierge-and-attentive-ai',
        'Food & Beverage 2023 Text Messaging Calendar',
      ]),
  },

  Company: {
    name: () => faker.company.name(),
    state: () => faker.address.state(),
    status: () => faker.helpers.arrayElement(['Active', 'Inactive']),
    addressCountry: () => faker.address.country(),
    country: () => faker.address.countryCode(),
  },

  GetGenerativeAIOptInStatusResponse: {
    optedIn: () => true,
  },
  Creative: {
    internalId: () => faker.datatype.number({ min: 1000, max: 1000000 }),
    displayName: () => faker.lorem.words(),
    language: () => 'en-US',
    ttjCode: () => faker.helpers.arrayElement(['JOIN', 'SOLSTICE', 'BDAY', 'PRIDE', 'VIP']),
    templateConfig: () => '{}',
    rules: () => '{}',
  },
  CreativeStatistics: {
    clickThroughRate: () => faker.datatype.float({ max: 1, precision: 0.001 }),
    clicks: () => faker.datatype.number({ max: 100000 }),
    impressions: () => faker.datatype.number({ max: 10000 }),
    smsConversionRate: () => faker.datatype.float({ max: 1, precision: 0.001 }),
    emailConversionRate: () => faker.datatype.float({ max: 1, precision: 0.001 }),
    smsSubscriberCount: () => faker.datatype.number({ max: 100000 }),
    emailSubscriberCount: () => faker.datatype.number({ max: 10000 }),
  },
  CreativeSchedule: {
    name: () =>
      faker.helpers.arrayElement([
        'Spring Sale',
        'Friends & Family Early Access',
        'Memorial Day Sale',
        'National PJ Day',
        'Black Friday Sale',
      ]),
  },
  CreativeTemplate: {
    internalId: () => faker.random.alphaNumeric(),
  },
  CouponSet: {
    name: () =>
      [
        `${faker.random.numeric(2)}% OFF`,
        faker.commerce.productAdjective(),
        faker.commerce.product(),
      ].join(' '),
  },
  Region: {
    countryCode: () => faker.address.countryCode(),
    languageTag: () => 'en-US',
  },
  Notification: {
    severity: () => faker.helpers.arrayElement(['success', 'info', 'error', 'warning']),
  },
  GetEnabledAttachmentTypesResponse: {
    attachmentTypes: () => [
      'ATTACHMENT_TYPE_OFFER',
      'ATTACHMENT_TYPE_EVENT_LOOKUP',
      'ATTACHMENT_TYPE_USER_PROPERTY',
      'ATTACHMENT_TYPE_RECOMMENDATION',
    ],
  },
  Journey: {
    name: () => faker.company.catchPhrase(),
    internalId: () => faker.datatype.number({ min: 1000, max: 1000000 }),
  },
  AutoJourney: {
    name: () => faker.company.catchPhrase(),
    status: () =>
      faker.helpers.arrayElement([
        'AUTO_JOURNEY_STATUS_ACTIVE',
        'AUTO_JOURNEY_STATUS_PAUSED',
        'AUTO_JOURNEY_STATUS_DELETED',
      ]),
  },
  AutoJourneyConfig: {
    journeyId: () => faker.datatype.number({ min: 1000, max: 1000000 }),
    trafficPercentage: () => faker.helpers.arrayElement([50.0, 100.0, 0.0]),
  },
  CustomParamsEntry: {
    key: () => faker.lorem.word(),
    value: () => faker.lorem.word(),
  },
  Segment: {
    name: () =>
      faker.helpers.arrayElement(
        Array.from({ length: 20 }, (_, i) => `Attentive segment ${i + 1}`)
      ),
  },
  AIGeneratedText: {
    source: () => '',
    text: () => faker.helpers.unique(faker.lorem.paragraph),
    groupId: () => faker.random.alphaNumeric(10),
    aiGeneratedTextMetadata: () => ({}),
  },
  SubmitGeneratedTextFeedbackPayload: {
    statusText: () => 'SUCCESS',
  },
  SaveGeneratedTextAndFeedbackPayload: {
    statusText: () => 'SUCCESS',
  },
  ImageLink: {
    created: () => faker.date.recent(),
    updated: () => faker.date.recent(),
    url: () =>
      faker.helpers.arrayElement([
        faker.image.imageUrl(640, 480, 'fashion'),
        faker.image.imageUrl(640, 480, 'food'),
        faker.image.imageUrl(640, 480, 'cats'),
        faker.image.imageUrl(640, 480, 'technics'),
        faker.image.imageUrl(640, 480, 'transport'),
      ]),
    height: () => 480,
    width: () => 640,
    position: () => faker.datatype.number(100),
  },
  Money: {
    amount: () => faker.finance.amount(),
    currency: () => faker.finance.currencyCode(),
  },
  Message: {
    body: () => faker.lorem.paragraph(),
    mediaUrls: () =>
      faker.helpers.arrayElement([
        faker.image.imageUrl(180, 180, 'food'),
        faker.image.imageUrl(180, 180, 'fashion'),
        faker.image.imageUrl(180, 180, 'cats'),
        faker.image.imageUrl(180, 180, 'technics'),
      ]),
  },
  Product: {
    name: () => faker.commerce.productName(),
    brand: () => faker.company.name(),
    description: () => faker.commerce.productDescription(),
    link: () => faker.internet.url(),
    originUpsertTime: () => faker.date.recent(),
    originPublishedAt: () => faker.date.recent(),
    created: () => faker.date.recent(),
    updated: () => faker.date.recent(),
    originId: () => faker.random.alphaNumeric(10),
    tags: () => [faker.commerce.department(), faker.commerce.productAdjective()],
    availability: () =>
      faker.helpers.arrayElement([
        'PRODUCT_AVAILABILITY_REMOVED',
        'PRODUCT_AVAILABILITY_IN_STOCK',
        'PRODUCT_AVAILABILITY_OUT_OF_STOCK',
        'PRODUCT_AVAILABILITY_PREORDER',
        'PRODUCT_AVAILABILITY_AVAILABLE_FOR_ORDER',
        'PRODUCT_AVAILABILITY_DISCONTINUED',
        'PRODUCT_AVAILABILITY_PENDING',
      ]),
    collections: () => [faker.commerce.department(), faker.commerce.productAdjective()],
  },
  ProductCategory: {
    name: () => faker.commerce.department(),
  },
  ProductCollection: {
    name: () => faker.commerce.productAdjective(),
  },
  ProductSegment: {
    name: () => `${faker.random.words(2)} feed`,
  },
  ProductVariant: {
    productOriginId: () => faker.random.alphaNumeric(10),
    created: () => faker.date.recent(),
    name: () => faker.commerce.productName(),
    availabilityDate: () => faker.date.soon(),
    originPublishedAt: () => faker.date.recent(),
    variantOriginId: () => faker.random.alphaNumeric(10),
    updated: () => faker.date.recent(),
    isDefault: () => faker.datatype.boolean(),
    originUpsertTime: () => faker.date.recent(),
    availability: () =>
      faker.helpers.arrayElement([
        'PRODUCT_AVAILABILITY_REMOVED',
        'PRODUCT_AVAILABILITY_IN_STOCK',
        'PRODUCT_AVAILABILITY_OUT_OF_STOCK',
        'PRODUCT_AVAILABILITY_PREORDER',
        'PRODUCT_AVAILABILITY_AVAILABLE_FOR_ORDER',
        'PRODUCT_AVAILABILITY_DISCONTINUED',
        'PRODUCT_AVAILABILITY_PENDING',
      ]),
    inventoryQuantity: () => faker.datatype.number({ min: 10000 }),
  },
  ProductVariantPrice: {
    created: () => faker.date.recent(),
    priceType: () =>
      faker.helpers.arrayElement(['VARIANT_PRICE_TYPE_REGULAR', 'VARIANT_PRICE_TYPE_SALE']),
    isActive: () => faker.datatype.boolean(),
    updated: () => faker.date.recent(),
  },

  AIBrandVoiceSettingsV2: {
    productTitleFormatting: () =>
      faker.helpers.arrayElement([
        'PRODUCT_TITLE_FORMATTING_TITLE_CASE',
        'PRODUCT_TITLE_FORMATTING_LOWER_CASE',
        'PRODUCT_TITLE_FORMATTING_UPPER_CASE',
      ]),
    allCapsUsage: () =>
      faker.helpers.arrayElement([
        'ALL_CAPS_USAGE_ADAPTIVE',
        'ALL_CAPS_USAGE_FREQUENTLY',
        'ALL_CAPS_USAGE_SPARINGLY',
        'ALL_CAPS_USAGE_NEVER',
      ]),
    emojiFrequency: () =>
      faker.helpers.arrayElement([
        'EMOJI_FREQUENCY_ADAPTIVE',
        'EMOJI_FREQUENCY_FREQUENTLY',
        'EMOJI_FREQUENCY_SPARINGLY',
        'EMOJI_FREQUENCY_NEVER',
      ]),
    urgency: () =>
      faker.helpers.arrayElement([
        'URGENCY_ADAPTIVE',
        'URGENCY_FREQUENTLY',
        'URGENCY_SPARINGLY',
        'URGENCY_NEVER',
      ]),
    toneKeywords: () => faker.helpers.arrayElement(['friendly', 'excited', 'funny', 'bold']),
  },
};
