export type IDGenerator = {
  generate: (type: string) => string;
  reset: (type?: string) => void;
};

export function makeIdGenrator(): IDGenerator {
  const ids: Record<string, number> = {};
  const _idGenerator = {
    reset: (type?: string) => {
      if (type) ids[type] = 0;
      else Object.keys(ids).forEach((key) => (ids[key] = 0));
    },
    generate(type: string) {
      ids[type] = (ids[type] ?? 0) + 1;

      return `${type}-${ids[type]}`;
    },
  };
  return _idGenerator;
}

export const idGenerator = makeIdGenrator();
