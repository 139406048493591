import merge from 'lodash/merge';

import { resolveField, buildResolverOptions } from './default-resolver';
import { FieldResolver } from './resolvers.type';

type UtmParametersInput = {
  utmParametersInput?: Array<{ key: string; value: string }> | null | undefined;
};

type EmailPreviewInput = {
  body: String;
  preheaderText?: String | null | undefined;
  subject: String;
};

type TextPreviewInput = {
  body: String;
  mediaUrl?: String | null | undefined;
  personalizedLinkId?: String | null | undefined;
};

type ProposedAttachment = {
  attachmentType: string;
};

type Args = {
  attachments?: ProposedAttachment[] | null | undefined;
  emailPreviewRequest?: EmailPreviewInput | null | undefined;
  productId?: string | null | undefined;
  textPreviewRequest?: TextPreviewInput | null | undefined;
  userCompositeId?: string | null | undefined;
  utmParameters?: UtmParametersInput | null | undefined;
};

export const resolveMessagePreview: FieldResolver<Args> = (source, args, context, info) => {
  const { emailPreviewRequest, textPreviewRequest, productId, userCompositeId } = args;
  let evaluatedChannelResponse: unknown = {};
  if (emailPreviewRequest) {
    evaluatedChannelResponse = {
      __typename: 'EvaluatedEmailResponse',
      evaluatedBody: `<html>mocked real subscriber preview for subscriber ID ${userCompositeId} with product ID ${productId} yo</html>`,
      evaluatedPreheaderText: 'mocked preheader text',
      evaluatedSubject: 'mocked subject',
      macrosWithoutContent: [],
    };
  } else if (textPreviewRequest) {
    evaluatedChannelResponse = {
      __typename: 'EvaluatedTextResponse',
      evaluatedBody: `mocked real subscriber preview for subscriber ID ${userCompositeId} with product ID ${productId} yo`,
      evaluatedMediaUrl: textPreviewRequest.mediaUrl
        ? 'https://static.wikia.nocookie.net/dragonquestmonster/images/3/30/Yeti-0.png/revision/latest'
        : null,
    };
  }

  const options = buildResolverOptions(args, context, info);
  return merge(
    {
      overrides: {
        evaluatedChannelResponse,
      },
    },
    resolveField(source, info.returnType, options)
  );
};
