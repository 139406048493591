import { extractRequestPersona } from '../../msw-utils';
import { mockedScopesForRoles, Persona, PersonaType, rolesForPersona } from '../../personas';
import { getOverrideValue } from '../generator/generator';

import { FieldResolver } from './resolvers.type';

export const resolveViewer: FieldResolver = (source, _args, context) => {
  let persona: PersonaType;

  try {
    persona = extractRequestPersona(context.headers);
  } catch (e) {
    console.warn(`${e.message}, using default persona "Remy"`);
    persona = Persona.Remy;
  }
  const roles = rolesForPersona(persona);

  const resolved = {
    roles: { roles },
    scopes: mockedScopesForRoles(roles),
    account: {
      id: persona.id,
      ...((getOverrideValue(source, 'account') as object) ?? {}),
    },
  };

  return {
    type: 'Viewer',
    id: 'Viewer-viewer',
    overrides: resolved,
  };
};
