import { graphql } from 'msw';

import { RequestHandler } from './RequestHandler';
import { groupHandlers } from './groupHandlers';
import { createOperationHandler } from './operationHandler';

type Config = {
  handlers?: RequestHandler[];
  mergeData?: boolean;
};
/**
 * Setup a mock request handler for Mock Service Worker.
 * @param {Config} config - Configuration object.
 * @returns {RequestHandler[]}
 */
export function setupMockHandlers(config: Config): RequestHandler[] {
  const { graphqlHandlers, restHandlers } = groupHandlers(config.handlers ?? []);
  const operationHandler = createOperationHandler({
    handlers: graphqlHandlers,
    mergeData: config.mergeData,
  });
  return [...restHandlers, graphql.operation(operationHandler)];
}
