import { faker } from '@faker-js/faker';

import { hash } from './hash';
import { idGenerator } from './id-generator';
import { mockMap } from './mocks-map';

import { validEnumValues } from '../__generated__/schema-info';

export type Source = {
  id: string;
  type: string;
  overrides?: {
    [key: string]: unknown;
  };
};

export function getOverrideValue(source: Source, fieldAlias: string) {
  return source.overrides && fieldAlias in source.overrides
    ? source.overrides[fieldAlias]
    : undefined;
}

export function getMockValueGenerator() {
  const seed = (s: string) => {
    faker.seed(Math.abs(hash(s)));
  };

  const map: Record<string, Record<string, Function>> = {
    ...mockMap,

    ID: {
      __default: idGenerator.generate,
    },
    String: {
      id: idGenerator.generate,
      __default: (parent: string, key: string) => `${parent}.${key}`,
      ...mockMap.String,
    },
    JSON: {
      __default: () => ({}),
    },
    Long: {
      __default: () => Number(faker.random.numeric(6)),
    },
    Int: {
      __default: () => Number(faker.random.numeric(6)),
    },
    Boolean: {
      __default: () => false,
    },
    Float: {
      __default: () => faker.datatype.float(),
    },
    DateTime: {
      __default: () => faker.datatype.datetime(),
    },
  };

  function mockValueGenerator(source: Source, type = '', key = '__default') {
    seed(source.id);

    // if someone is asking for an enum, just pick a valid value
    if (type in validEnumValues) {
      return faker.helpers.arrayElement(validEnumValues[type]);
    }

    const parentType = source.type;
    // the key exist on the parent type e.g. Company.name
    if (map[parentType] && key && map[parentType][key]) {
      return map[parentType][key](parentType, key);
    } // the type exist on the map e.g. String
    else if (map[type]) {
      // the key exist on the node type e.g. String.name
      if (key && map[type][key]) {
        return map[type][key](parentType, key);
      } // a default function exists on the type e.g. String.__default
      else if (map[type].__default) {
        return map[type].__default(parentType, key);
      }
    }
    // no mock value generated
    return undefined;
  }

  return {
    reset: () => {
      faker.seed(0);
      idGenerator.reset();
    },
    generateId: idGenerator.generate,
    resetId: idGenerator.reset,
    generateValue: mockValueGenerator,
    choose: <T>(source: Source, types: T[]): T => {
      seed(source.id);
      return faker.helpers.arrayElement(types);
    },
    seed,
  };
}

export type Generator = ReturnType<typeof getMockValueGenerator>;
