import { Persona } from '../personas';

export class Store {
  private data = new Map();

  constructor() {
    this.seed();
  }
  private toMap(obj: Object) {
    return new Map(Object.entries(obj));
  }
  private toObj(map: Map<string, unknown>) {
    return Object.fromEntries(map);
  }
  private seed() {
    this.set(Persona.Sally.id, {
      firstName: Persona.Sally.firstName,
      lastName: Persona.Sally.lastName,
      email: Persona.Sally.email,
      phone: Persona.Sally.phone,
    })
      .set(Persona.Remy.id, {
        firstName: Persona.Remy.firstName,
        lastName: Persona.Remy.lastName,
        email: Persona.Remy.email,
        phone: Persona.Remy.phone,
      })
      .set(Persona.Carl.id, {
        firstName: Persona.Carl.firstName,
        lastName: Persona.Carl.lastName,
        email: Persona.Carl.email,
        phone: Persona.Carl.phone,
      });
  }
  reset() {
    this.data = new Map();
    this.seed();
  }
  set(id: string, data: object) {
    this.data.set(id, this.toMap(data));
    return this;
  }
  get(id: string, fieldName?: string) {
    if (!this.data.has(id)) return null;
    if (!fieldName) return this.toObj(this.data.get(id));
    return this.data.get(id).get(fieldName);
  }
}

export const store = new Store();
