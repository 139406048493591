import { GQLTypeNames, GraphQLNode } from '../types';

export const createNode = <T extends GQLTypeNames, N extends GraphQLNode | null>(
  typeName: T,
  properties: N
) => {
  return {
    __typename: typeName,
    ...properties,
  };
};

export const createNodes = <T extends GQLTypeNames, N extends GraphQLNode | null>(
  typeName: T,
  nodes: N[]
) => {
  return nodes.map((node) => {
    if (!node) {
      return null;
    }

    return {
      ...node,
      __typename: typeName,
    };
  }) as Array<({ __typename: T } & NonNullable<N>) | null>;
};
