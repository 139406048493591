import merge from 'lodash/merge';

import { resolveField, buildResolverOptions } from './default-resolver';
import { FieldResolver } from './resolvers.type';

type ProposedAttachment = {
  attachmentType: string;
};

type Args = {
  channel: String;
  contentCategory?: String | null;
  contentMessageType?: String | null;
  proposedAttachments?: ProposedAttachment[];
};

export const resolveCompanyCompatibleContent: FieldResolver<Args> = (
  source,
  args,
  context,
  info
) => {
  const { channel, contentCategory, contentMessageType, proposedAttachments = [] } = args;
  const composeContent: unknown[] = [];

  // global email content
  if (channel === 'COMPOSE_MESSAGE_CHANNEL_EMAIL') {
    composeContent.push(
      {
        attachmentType: 'ATTACHMENT_TYPE_UNKNOWN',
        macroRoot: {
          extension: 'unsubscribeLink',
          behavior: 'MACRO_BEHAVIOR_LINK',
          displayName: 'Unsubscribe Link',
          macroAttributes: [],
        },
      },
      {
        attachmentType: 'ATTACHMENT_TYPE_UNKNOWN',
        macroRoot: {
          extension: 'webView',
          behavior: 'MACRO_BEHAVIOR_LINK',
          displayName: 'Web View Link',
          macroAttributes: [],
        },
      },
      {
        attachmentType: 'ATTACHMENT_TYPE_UNKNOWN',
        macroRoot: {
          extension: 'companyAddress',
          behavior: 'MACRO_BEHAVIOR_TEXT',
          displayName: 'Company Address',
          macroAttributes: [],
        },
      },
      {
        attachmentType: 'ATTACHMENT_TYPE_UNKNOWN',
        macroRoot: {
          extension: 'companyLink',
          behavior: 'MACRO_BEHAVIOR_LINK',
          displayName: 'Company Link',
          macroAttributes: [],
        },
      }
    );
  }

  // offer content
  if (
    proposedAttachments.some(({ attachmentType }) => attachmentType === 'ATTACHMENT_TYPE_OFFER')
  ) {
    composeContent.push({
      attachmentType: 'ATTACHMENT_TYPE_OFFER',
      macroRoot: {
        extension: 'offer',
        behavior: 'MACRO_BEHAVIOR_OBJECT',
        displayName: 'Offer',
        macroAttributes: [
          {
            extension: 'value',
            behavior: 'MACRO_BEHAVIOR_TEXT',
            displayName: 'Coupon Value',
            macroAttributes: [],
          },
          {
            extension: 'code',
            behavior: 'MACRO_BEHAVIOR_TEXT',
            displayName: 'Coupon Code',
            macroAttributes: [],
          },
          {
            extension: 'link',
            behavior: 'MACRO_BEHAVIOR_LINK',
            displayName: 'Coupon Link',
            macroAttributes: [],
          },
        ],
      },
    });
  }

  // subscriber content
  if (
    proposedAttachments.some(
      ({ attachmentType }) => attachmentType === 'ATTACHMENT_TYPE_USER_PROPERTY'
    )
  ) {
    composeContent.push({
      attachmentType: 'ATTACHMENT_TYPE_USER_PROPERTY',
      macroRoot: {
        extension: 'subscriber',
        behavior: 'MACRO_BEHAVIOR_OBJECT',
        displayName: 'Subscriber',
        macroAttributes: [
          {
            extension: 'lastName',
            behavior: 'MACRO_BEHAVIOR_TEXT',
            displayName: 'Last Name',
            macroAttributes: [],
          },
          {
            extension: 'firstName',
            behavior: 'MACRO_BEHAVIOR_TEXT',
            displayName: 'First Name',
            macroAttributes: [],
          },
          {
            extension: 'signupOffer',
            behavior: 'MACRO_BEHAVIOR_OBJECT',
            displayName: 'Sign-up Offer',
            macroAttributes: [
              {
                extension: 'code',
                behavior: 'MACRO_BEHAVIOR_TEXT',
                displayName: 'Coupon Code',
                macroAttributes: [],
              },
              {
                extension: 'link',
                behavior: 'MACRO_BEHAVIOR_LINK',
                displayName: 'Coupon Link',
                macroAttributes: [],
              },
              {
                extension: 'couponScreenLink',
                behavior: 'MACRO_BEHAVIOR_LINK',
                displayName: 'Coupon Screen Link',
                macroAttributes: [],
              },
            ],
          },
          {
            extension: 'fullName',
            behavior: 'MACRO_BEHAVIOR_TEXT',
            displayName: 'Full Name',
            macroAttributes: [],
          },
          {
            extension: 'custom',
            behavior: 'MACRO_BEHAVIOR_OBJECT',
            displayName: 'Custom Attributes',
            macroAttributes: [
              {
                extension: 'Favorite Coffee',
                behavior: 'MACRO_BEHAVIOR_TEXT',
                displayName: 'Favorite Coffee',
                macroAttributes: [],
              },
              {
                extension: 'Subscriber Tier Badge Image',
                behavior: 'MACRO_BEHAVIOR_IMAGE',
                displayName: 'Subscriber Tier Badge',
                macroAttributes: [],
              },
            ],
          },
        ],
      },
    });
  }

  // product rec content
  if (
    proposedAttachments.some(
      ({ attachmentType }) => attachmentType === 'ATTACHMENT_TYPE_RECOMMENDATION'
    )
  ) {
    composeContent.push({
      attachmentType: 'ATTACHMENT_TYPE_RECOMMENDATION',
      macroRoot: {
        extension: 'productRecommendations',
        behavior: 'MACRO_BEHAVIOR_OBJECT',
        displayName: 'Product Recommendations',
        macroAttributes: [
          {
            extension: 'products',
            behavior: 'MACRO_BEHAVIOR_OBJECT_LIST',
            displayName: 'Products',
            macroAttributes: [
              {
                extension: 'image',
                behavior: 'MACRO_BEHAVIOR_IMAGE',
                displayName: 'Image',
                macroAttributes: [],
              },
              {
                extension: 'name',
                behavior: 'MACRO_BEHAVIOR_TEXT',
                displayName: 'Name',
                macroAttributes: [],
              },
              {
                extension: 'link',
                behavior: 'MACRO_BEHAVIOR_LINK',
                displayName: 'Link',
                macroAttributes: [],
              },
              {
                extension: 'price',
                behavior: 'MACRO_BEHAVIOR_TEXT',
                displayName: 'Price',
                macroAttributes: [],
              },
            ],
          },
        ],
      },
    });
  }

  // event lookup content
  if (
    proposedAttachments.some(
      ({ attachmentType }) => attachmentType === 'ATTACHMENT_TYPE_EVENT_LOOKUP'
    )
  ) {
    composeContent.push(
      {
        attachmentType: 'ATTACHMENT_TYPE_EVENT_LOOKUP',
        macroRoot: {
          behavior: 'MACRO_BEHAVIOR_OBJECT',
          extension: 'triggerEvent',
          displayName: 'triggerEvent',
          macroAttributes: [
            {
              behavior: 'MACRO_BEHAVIOR_OBJECT_LIST',
              extension: 'products',
              displayName: 'products',
              macroAttributes: [
                {
                  behavior: 'MACRO_BEHAVIOR_LINK',
                  extension: 'link',
                  displayName: 'link',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'price',
                  displayName: 'price',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_IMAGE',
                  extension: 'image',
                  displayName: 'image',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'name',
                  displayName: 'name',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'otherProperties',
                  displayName: 'otherProperties',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'size',
                  displayName: 'size',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'color',
                  displayName: 'color',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'quantity',
                  displayName: 'quantity',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'unitPrice',
                  displayName: 'unitPrice',
                  macroAttributes: [],
                },
              ],
            },
          ],
        },
      },
      {
        attachmentType: 'ATTACHMENT_TYPE_EVENT_LOOKUP',
        macroRoot: {
          behavior: 'MACRO_BEHAVIOR_OBJECT',
          extension: 'triggerEvent',
          displayName: 'triggerEvent',
          macroAttributes: [
            {
              behavior: 'MACRO_BEHAVIOR_OBJECT',
              extension: 'product',
              displayName: 'product',
              macroAttributes: [
                {
                  behavior: 'MACRO_BEHAVIOR_LINK',
                  extension: 'link',
                  displayName: 'link',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'price',
                  displayName: 'price',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_IMAGE',
                  extension: 'image',
                  displayName: 'image',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'name',
                  displayName: 'name',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'otherProperties',
                  displayName: 'otherProperties',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'size',
                  displayName: 'size',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'color',
                  displayName: 'color',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'quantity',
                  displayName: 'quantity',
                  macroAttributes: [],
                },
                {
                  behavior: 'MACRO_BEHAVIOR_TEXT',
                  extension: 'unitPrice',
                  displayName: 'unitPrice',
                  macroAttributes: [],
                },
              ],
            },
          ],
        },
      }
    );
  }

  const options = buildResolverOptions(args, context, info);
  return merge(
    {
      overrides: {
        contentCategory: contentCategory ?? 'CONTENT_CATEGORY_UNSPECIFIED',
        contentMessageType: contentMessageType ?? 'CONTENT_MESSAGE_TYPE_UNSPECIFIED',
        composeContent,
      },
    },
    resolveField(source, info.returnType, options)
  );
};
