/**
 * Decodes an encoded ID and returns the Type and index.
 *
 * The ID can be encoded in two formats:
 * 1. Using a hyphen as a separator between type and index, e.g., "type-index"
 * 2. Base64 encoded string, e.g., "aW5kZXg6dHlwZQ==", which decodes to "index:type"
 *
 * @param {string} id - The encoded ID to be decoded.
 * @returns {[string, string]} - An array containing the decoded type and index.
 * @throws Will throw an error if the provided ID can't be decoded.
 * @example
 * const [type, index] = decodeID('NzpDb21wYW55LTc='); // Returns ['Company', 7]
 * const [type, index] = decodeID('Company-7'); // Returns ['Company', 7]
 */
export function decodeID(id: string): [string, string] {
  if (id.includes('-')) {
    const [type, index] = id.split('-');
    return [capitalizeFirstLetter(type), index];
  }

  let decodedId = '';
  try {
    decodedId = atob(id);
  } catch {
    throw new Error(`Failed to decode provided ID "${id}".`);
  }
  const [index, type] = decodedId.split(':');
  if (!type)
    throw new Error(
      `Failed to decode provided ID (${id}). make sure you include the node type with the ID. e.g. Company-1`
    );
  return [type.replace(/[^a-zA-Z]+/g, ''), index];
}

export function encodeID(type: string, index = 1) {
  return `${type}-${index}`;
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
