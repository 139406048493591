import {
  getNullableType,
  GraphQLInterfaceType,
  GraphQLLeafType,
  GraphQLObjectType,
  GraphQLOutputType,
  GraphQLResolveInfo,
  GraphQLUnionType,
  isLeafType,
  isListType,
  isUnionType,
} from 'graphql';

import { getOverrideValue, Source } from '../generator/generator';

import { Context, FieldResolver } from './resolvers.type';

export type Options = {
  args: Record<string, unknown>;
  context: Context;
  /* The name of a field in the GraphQL schema */
  fieldName: string;
  /* The name of a field in the output of a particular query */
  fieldAlias: string;
};

/**
 * Resolves the value of a leaf type for a given source and field type.
 */
function resolveLeafType(
  source: Source,
  fieldType: GraphQLLeafType,
  {
    fieldName,
    fieldAlias,
    context: {
      store,
      generator: { generateValue },
    },
  }: Options
) {
  // If the value exists in the source object, return it.
  const override = getOverrideValue(source, fieldAlias);
  if (override !== undefined) {
    return override;
  }

  if (fieldName === 'id' && fieldType.name === 'ID') {
    return source.id;
  }

  const value = store.get(source.id, fieldName);
  if (value) {
    return value;
  }
  const type = fieldType.name;

  return generateValue(source, type, fieldName);
}

type ObjectishType = GraphQLObjectType | GraphQLInterfaceType;
/**
 * Resolves GraphQLObjectType or GraphQLInterfaceType.
 * return a reference object.
 */
function resolveObjectType(
  source: Source,
  type: ObjectishType,
  {
    fieldAlias,
    context: {
      generator: { generateId },
    },
  }: Options
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const overrides: any = getOverrideValue(source, fieldAlias);

  // If someone asked for a null object, they get a null object
  if (overrides === null) {
    return null;
  }

  // If someone asked for a specific object, return a source that will produce it
  if (overrides !== undefined) {
    const id = 'id' in overrides ? overrides.id : generateId(type.name);
    return { type: type.name, id, overrides } as Source;
  }

  const id = generateId(type.name);
  return { type: type.name, id } as Source;
}
/**
 * Resolves the list type for a given source and element type.
 */
function resolveListType(
  source: Source,
  elementType: GraphQLOutputType,
  options: Options
): unknown[] | null {
  const { fieldAlias } = options;
  const overriddenValue = getOverrideValue(source, fieldAlias);

  // If someone asked for a null list, they get a null list
  if (overriddenValue === null) {
    return null;
  }

  // Build as many values as the caller asked for, but default to 10
  const overrides = Array.isArray(overriddenValue) ? overriddenValue : Array.from({ length: 10 });

  return overrides.map((override, i) => {
    const childSource: Source = {
      type: source.type,
      id: `${source.id}-${fieldAlias}-${i}`,
      overrides: {
        [fieldAlias]: override,
      },
    };
    return resolveField(childSource, elementType, options);
  });
}

export function resolveUnionType(source: Source, unionType: GraphQLUnionType, options: Options) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const overriddenValue: any = getOverrideValue(source, options.fieldAlias);

  // If someone asked for a null value, they get a null value
  if (overriddenValue === null) {
    return null;
  }

  // pick one of the valid options for a union type
  const types = unionType.getTypes();
  let resolvedType: GraphQLObjectType | undefined;
  if (overriddenValue && '__typename' in overriddenValue) {
    const typename = overriddenValue.__typename;
    resolvedType = types.find((t) => t.name === typename);
  }
  if (!resolvedType) {
    resolvedType = options.context.generator.choose(source, types);
  }

  return resolveObjectType(source, resolvedType, options);
}
/**
 * Resolves the value of a field based on its type and the source object.
 */
export function resolveField(
  source: Source,
  fieldType: GraphQLOutputType,
  options: Options
): unknown {
  // Get the inner type of the fieldType.
  const innerType = getNullableType(fieldType);

  if (isListType(innerType)) {
    return resolveListType(source, innerType.ofType, options);
  }
  if (isLeafType(innerType)) {
    return resolveLeafType(source, innerType, options);
  }
  if (isUnionType(innerType)) {
    return resolveUnionType(source, innerType, options);
  }
  // If the inner type is an GraphQLObjectType or GraphQLInterfaceType
  // resolve the object type and return a reference object.
  return resolveObjectType(source, innerType, options);
}

export function buildResolverOptions(
  args: Record<string, unknown>,
  context: Context,
  info: GraphQLResolveInfo
) {
  return {
    fieldName: info.fieldName,
    fieldAlias: info.path.key.toString(),
    args,
    context,
  };
}

export const defaultResolver: FieldResolver = (source, args, context, info) => {
  return resolveField(source, info.returnType, buildResolverOptions(args, context, info));
};
