import { Story, Args } from '@storybook/react';
import { DelayMode, GraphQLRequest, GraphQLVariables } from 'msw';
import React from 'react';
import { ConcreteRequest } from 'relay-runtime';

type DeepPartial<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? DeepPartial<K[attr]>
    : K[attr] extends object | null
    ? DeepPartial<K[attr]> | null
    : K[attr] extends object | null | undefined
    ? DeepPartial<K[attr]> | null | undefined
    : K[attr];
};

type RelayQueryType = {
  variables: GraphQLVariables;
  response: Record<string, unknown>;
  rawResponse: Record<string, unknown>;
};
type OverrideConfig = {
  delay?: number | DelayMode | undefined;
  networkError?: string | undefined;
};
type OverrideDataGenerator = (req: GraphQLRequest<GraphQLVariables>) => Record<string, unknown>;
type OverrideConfigGenerator = (req: GraphQLRequest<GraphQLVariables>) => OverrideConfig;

type StoryContext = { args: Args };

const overrideStore: Record<
  string,
  { dataGenerator: OverrideDataGenerator; configGenerator?: OverrideConfigGenerator }
> = {};

function getDataOverride(operationName: string) {
  return overrideStore[operationName];
}

function addDataOverride(
  operationName: string,
  dataGenerator: OverrideDataGenerator,
  configGenerator: OverrideConfigGenerator
) {
  overrideStore[operationName] = {
    dataGenerator,
    configGenerator,
  };

  return () => {
    delete overrideStore[operationName];
  };
}

type ProviderProps<T extends RelayQueryType> = {
  query: ConcreteRequest;
  data: (req: GraphQLRequest<T['variables']>) => DeepPartial<T['rawResponse']>;
  config?: OverrideConfigGenerator;
  children: React.ReactNode;
};

function MockDataOverrideProvider<T extends RelayQueryType>({
  query,
  data,
  children,
  config = () => ({}),
}: ProviderProps<T>) {
  const queryName = query.operation.name;
  const cleanupRef = React.useRef(addDataOverride(queryName, data, config));

  React.useEffect(() => {
    cleanupRef.current = addDataOverride(queryName, data, config);
    return cleanupRef.current;
  }, [queryName, data, config]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

function MockDataOverrideDecorator<T extends RelayQueryType>(
  query: ConcreteRequest,
  dataGenerator: (
    req: GraphQLRequest<T['variables']>,
    ctx: StoryContext
  ) => DeepPartial<T['rawResponse']>,
  configGenerator: (
    req: GraphQLRequest<T['variables']>,
    ctx: StoryContext
  ) => OverrideConfig = () => ({})
) {
  return function DataOverrideDecorator(StoryFn: Story, context: StoryContext) {
    const data = (r: GraphQLRequest<T['variables']>) => dataGenerator(r, context);
    const config = (r: GraphQLRequest<T['variables']>) => configGenerator(r, context);

    return (
      <MockDataOverrideProvider query={query} data={data} config={config}>
        <StoryFn />
      </MockDataOverrideProvider>
    );
  };
}

export { getDataOverride, MockDataOverrideProvider, MockDataOverrideDecorator };
