export const nbaImages = [
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/ADD_CUSTOM_ATTRIBUTES.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/CREATE_AB_TEST_CAMPAIGN.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/CREATE_BACK_IN_STOCK_JOURNEY.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/CREATE_BROWSE_ABANDONMENT_JOURNEY.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/CREATE_CART_ABANDONMENT_JOURNEY.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/CREATE_CONVERSATIONAL_JOURNEY.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/CREATE_DESKTOP_FULLSCREEN.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/CREATE_MOBILE_FULLSCREEN.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/CREATE_ORDER_CONFIRMATION_JOURNEY.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/CREATE_ORDER_SHIPPED_JOURNEY.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/CREATE_POST_PURCHASE_JOURNEY.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/CREATE_SEGMENT_SEND_TARGETED_MESSAGE.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/CREATE_WINBACK_JOURNEY.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/DEFAULT.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/DOWNLOAD_REPORT_TRACK_PERFORMANCE.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/LAUNCH_FIRST_CAMPAIGN.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/RECHARGE_TRANSACTIONAL_JOURNEY.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/SCHEDULE_CAMPAIGN.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/SEND_MESSAGE_FULL_LIST_EVERY_30_DAYS.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/SETUP_CONTACT_CARD.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/SET_UP_CX_INTEGRATION.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/SET_UP_EMAIL_INTEGRATION.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/SET_UP_SHOPIFY_INTEGRATION.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/USE_CAMPAIGN_TEMPLATES.png',
  'https://client-ui-assets.s3.amazonaws.com/db-assets/images/VIEW_ANALYTICS_DASHBOARD.png',
];

export const blogImages = [
  'https://assets-global.website-files.com/5f8dd331b45e61f0a3ac9861/61dc9a757c1dbdefdda98704_TextMessagingCalendar_2022_Blog_1080x680.png',
  'https://assets-global.website-files.com/5f8dd331b45e61f0a3ac9861/64403ca93ff4d12d762aa66c_AI%20Concierge%20Launch-feat-image_1080x680.png',
  'https://assets-global.website-files.com/5f8dd331b45e61f0a3ac9861/64399c18db099016450f80a0_Travel-Blog_feat-image_1080x680-p-500.png',
  'https://assets-global.website-files.com/5f8dd331b45e61f0a3ac9861/62616962fe37ed9eb07c13e0_TWL%20Mother_s%20Day-Feat-Image_1080x680-p-500.png',
  'https://assets-global.website-files.com/5f8dd331b45e61f0a3ac9861/62a3810e813a33b179bb2cf6_F%26B_SMS_Blog_Image_1080x680-p-500.png',
];
