import { addResolversToSchema } from '@graphql-tools/schema';
import { MapperKind, mapSchema } from '@graphql-tools/utils';
import {
  graphql,
  GraphQLError,
  buildSchema,
  GraphQLUnionType,
  isUnionType,
  GraphQLInterfaceType,
  DocumentNode,
  parse,
} from 'graphql';
import merge from 'lodash/merge';
import { graphql as mswGraphql, GraphQLHandler, GraphQLRequest, GraphQLVariables } from 'msw';

import { getDataOverride } from './dataOverrideStore';
import { getMockValueGenerator, Source } from './generator/generator';
import { meld } from './helpers/meld';
import { resolvers } from './resolvers';
import { defaultResolver } from './resolvers/default-resolver';
import { store } from './store';

import { schema as schemaAsString } from './__generated__/schema';

/**
 * Builds a GraphQL schema based on a schema string and adds default resolvers to it.
 */
function getSchema() {
  // Build the initial schema from the schema string.
  let schema = buildSchema(schemaAsString);

  // Map over the schema and add default resolvers to object fields.
  schema = mapSchema(schema, {
    [MapperKind.OBJECT_FIELD]: (config) => {
      return {
        ...config,
        resolve: defaultResolver,
      };
    },
    [MapperKind.ABSTRACT_TYPE]: (type) => {
      if (isUnionType(type)) {
        return new GraphQLUnionType({
          ...type.toConfig(),
          resolveType: (source: Source) => source.type,
        });
      }
      return new GraphQLInterfaceType({
        ...type.toConfig(),
        resolveType: (source: Source) => source.type,
      });
    },
  });

  // Add the resolvers to the schem
  schema = addResolversToSchema({
    schema,
    resolvers: resolvers(),
  });

  return schema;
}

async function getHandlerResponses(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handlers: Array<GraphQLHandler<GraphQLRequest<any>>>,
  req: GraphQLRequest<GraphQLVariables>
) {
  // Create empty arrays for the data and errors.
  const dataList = [];
  const errorList = [];
  // Collect results from handlers
  const results = await Promise.all(handlers.map(async (handler) => await handler.run(req)));

  for (const handler of results) {
    const responseBody: string = handler?.response?.body;
    if (!responseBody) continue;

    const { data, errors } = JSON.parse(responseBody);
    if (data) {
      dataList.push(data);
    }
    if (errors) {
      errorList.push(...errors);
    }
  }

  return {
    data: dataList,
    errors: errorList,
  };
}

type OperationHandlerConfig = {
  handlers?: GraphQLHandler[];
  mergeData?: boolean;
};

/**
 * a catch-all handler that will return a mocked response for a graphql query
 */
export function createOperationHandler(
  config: OperationHandlerConfig = {}
): Parameters<typeof mswGraphql.operation>[0] {
  const { handlers = [], mergeData } = config ?? [];

  const generator = getMockValueGenerator();
  const schema = getSchema();

  function extractOperationInfo(queryDoc: DocumentNode) {
    for (const def of queryDoc.definitions) {
      if (def.kind === 'OperationDefinition') {
        const operationName = def.name?.value ?? null;
        const operationType = def.operation;
        return { operationName, operationType };
      }
    }
    throw new Error('No query found');
  }

  return async (req, res, ctx) => {
    generator.reset();
    const relevantHandlers = handlers.filter((handler) => handler.test(req));
    const { operationName, operationType } = extractOperationInfo(parse(req.body?.query));
    const relevantOverrideHandler = (operationName && getDataOverride(operationName)) || null;

    const hasRelevantHandlers = relevantHandlers.length > 0;
    // we do the work of generating dynamic data if there are no relevant handlers
    // or if there are relevant handlers and the mergeData flag is set to true
    const shouldGenerateDynamicMockData =
      !hasRelevantHandlers || (hasRelevantHandlers && mergeData);
    // holds a list of resolved response data returned from mock handlers
    let responses: {
      data: Array<Record<string, unknown>>;
      errors: GraphQLError[];
    } = {
      data: [],
      errors: [],
    };

    /* run the operation handler */
    if (shouldGenerateDynamicMockData && req.body?.query) {
      const resolvedData = await graphql({
        schema,
        source: req.body.query,
        variableValues: req.body.variables,
        contextValue: {
          headers: req.headers,
          store,
          generator,
        },
        rootValue: {
          id: 'root',
          type: operationType === 'mutation' ? 'Mutation' : 'Query',
          overrides: relevantOverrideHandler?.dataGenerator(req),
        } as Source,
      });

      if (resolvedData.data) {
        responses.data.push(resolvedData.data);
      }
      if (resolvedData.errors) {
        responses.errors.push(...resolvedData.errors);
      }
    }

    // Run the relevant MFE/Lib provided and override handlers, collect their
    // responses, and merge into a final response collection
    responses = merge(responses, await getHandlerResponses(relevantHandlers, req));

    if (responses.data.length || responses.errors.length) {
      const args = [ctx.data(meld(...responses.data))];
      // attach the errors array if there are any
      if (responses.errors.length) {
        args.push(ctx.errors(responses.errors));
      }
      // check for override configuration
      if (relevantOverrideHandler?.configGenerator) {
        const overrideConfig = relevantOverrideHandler.configGenerator(req);

        if (overrideConfig.delay) {
          args.push(ctx.delay(overrideConfig.delay));
        }
        if (overrideConfig.networkError) {
          return res.networkError(overrideConfig.networkError);
        }
      }

      return res(...args);
    }

    return res();
  };
}
