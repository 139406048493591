import { RestHandler, GraphQLHandler } from 'msw';

import { RequestHandler } from './RequestHandler';

/**
 * Iterate over the request handlers and group them by type
 * @param handlers
 * @returns
 */

type GroupedHandlers = {
  restHandlers: RestHandler[];
  graphqlHandlers: GraphQLHandler[];
};

export function groupHandlers(handlers: RequestHandler[]): GroupedHandlers {
  const groupByType = (acc: GroupedHandlers, handler: RequestHandler) => {
    if (handler instanceof RestHandler) {
      acc.restHandlers.push(handler);
    } else if (handler instanceof GraphQLHandler) {
      acc.graphqlHandlers.push(handler);
    }
    return acc;
  };

  return handlers.reduce(groupByType, { restHandlers: [], graphqlHandlers: [] });
}
