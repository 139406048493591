type UnknownObject = Record<string, unknown>;

function isObject(value: unknown): value is UnknownObject {
  return value != null && typeof value === 'object' && !Array.isArray(value);
}

function mergeRight(left: UnknownObject, right: UnknownObject) {
  return Object.entries(right).reduce((result, [key, rightValue]) => {
    const leftValue = result[key];

    if (leftValue === undefined) return result;
    if (Array.isArray(leftValue) && Array.isArray(rightValue)) {
      result[key] = leftValue.map((val, idx) => {
        if (isObject(val)) {
          if (rightValue[idx] !== undefined) {
            return mergeRight(val, rightValue[idx]);
          }
          return val;
        } else if (rightValue[idx]) {
          return rightValue[idx];
        }
        return val;
      });

      return result;
    }

    if (isObject(leftValue) && isObject(rightValue)) {
      result[key] = mergeRight(leftValue, rightValue);
      return result;
    }
    if (typeof leftValue === typeof rightValue || rightValue === null) {
      result[key] = rightValue;
    }

    return result;
  }, Object.assign({}, left));
}

/**
 * merge two or more objects folowing specific rules:
 * - rightmost object takes precedence
 * - right key must exist on left object
 * - right index must exist on left array
 * - type of value must match on left and right
 */
function meld<T extends UnknownObject>(...args: T[]): T {
  const [left, ...toMerge] = args;
  if (toMerge.length === 0) return left;
  const data = toMerge.reduce((acc, curr) => {
    return mergeRight(acc, curr) as T;
  }, left as T);

  return data;
}

export { meld };
