import { defaults } from '@attentive/nodash';

import { PersonaType } from '../personas';
import { GraphQLTypeProperties } from '../types';

export const createAccount = <T extends GraphQLTypeProperties>(
  who: PersonaType,
  properties?: T
) => {
  return defaults(properties, who);
};
