import { assertListType, assertObjectType, getNullableType } from 'graphql';
import merge from 'lodash/merge';

import { Source } from '../generator/generator';

import { buildResolverOptions, resolveField } from './default-resolver';
import { FieldResolver, Resolvers } from './resolvers.type';

import { connectionFields } from '../__generated__/schema-info';

type PaginationParams = {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
};

const computePagination = (cursors: string[], { first, before, last, after }: PaginationParams) => {
  let startIndex = 0;
  let endIndex = cursors.length;

  if (after) {
    const afterIndex = cursors.indexOf(after);
    if (afterIndex !== -1) {
      startIndex = Math.max(startIndex, afterIndex + 1);
    }
  }
  if (before) {
    const beforeIndex = cursors.indexOf(before);
    if (beforeIndex !== -1) {
      endIndex = Math.min(endIndex, beforeIndex);
    }
  }

  if (typeof first === 'number') {
    endIndex = Math.min(endIndex, startIndex + first);
  }
  if (typeof last === 'number') {
    startIndex = Math.max(startIndex, endIndex - last);
  }

  return { startIndex, endIndex };
};

type Edge = Record<string, unknown> & {
  id: string;
  cursor: string;
  node: Record<string, unknown>;
};
export const applyPagination = (allEdges: Source[], allNodes: Source[], args: PaginationParams) => {
  // use node ids as cursors, for more intuitive ad-hoc querying
  const cursors = allNodes.map((n) => n.id);
  const { startIndex, endIndex } = computePagination(cursors, args);

  const edges: Edge[] = [];
  for (let i = startIndex; i < endIndex; ++i) {
    edges.push({
      id: allEdges[i].id,
      cursor: cursors[i],
      ...allEdges[i].overrides,
      node: {
        id: allNodes[i].id,
        ...allNodes[i].overrides,
      },
    });
  }

  const notEmpty = !!edges.length;
  const hasPreviousPage = notEmpty && allEdges[0].id !== edges[0].id;
  const hasNextPage = notEmpty && allEdges[allEdges.length - 1].id !== edges[edges.length - 1].id;
  const startCursor = notEmpty ? edges[0].cursor : '';
  const endCursor = notEmpty ? edges[edges.length - 1].cursor : '';

  return {
    edges,
    pageInfo: {
      hasPreviousPage,
      hasNextPage,
      startCursor,
      endCursor,
    },
    totalCount: allEdges.length,
  };
};

const connectionResolver: FieldResolver<PaginationParams> = (source, args, context, info) => {
  const options = buildResolverOptions(args, context, info);

  // Get a "source" for the entire connection
  const connectionType = assertObjectType(info.returnType);
  const baseConnectionSource = resolveField(source, connectionType, options) as Source;

  // Run default logic to get a "source" for each edge in the object
  const edgesType = getNullableType(connectionType.getFields().edges.type);
  const edgeSources = resolveField(baseConnectionSource, edgesType, {
    ...options,
    fieldName: 'edges',
    fieldAlias: 'edges',
  }) as Source[];

  const edgeType = assertListType(getNullableType(edgesType)).ofType;
  const nodeType = getNullableType(edgeType).getFields().node.type;
  const nodeSources = edgeSources.map(
    (e) =>
      resolveField(e, nodeType, {
        ...options,
        fieldName: 'node',
        fieldAlias: 'node',
      }) as Source
  );

  // Apply pagination logic to filter which edges
  const { edges, pageInfo, totalCount } = applyPagination(edgeSources, nodeSources, args);

  // Return a "source" with all these fields already set.
  // The default resolver will use the values we provided here.
  return {
    ...baseConnectionSource,
    overrides: {
      ...baseConnectionSource.overrides,
      edges,
      pageInfo,
      totalCount,
    },
  };
};

export const createConnectionResolvers = (): Resolvers => {
  return connectionFields.reduce((resolvers, field) => {
    const { objectTypeName, fieldName } = field;
    return merge(resolvers, {
      [objectTypeName]: {
        [fieldName]: connectionResolver,
      },
    });
  }, {} as Resolvers);
};
